import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import { APREMIA_EMAIL, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
// import Map from "../components/map"
import ContactForm from "../components/contactForm"

import IconCallImage from "../images/icon-call.svg"
import IconMailImage from "../images/icon-mail.svg"
import IconLocationImage from "../images/icon-location.svg"

const Icon = styled.svg`
  fill: ${props => props.theme.colors["blue"]};
  height: 24px;
`

const Map = styled.div`
  height: 340px;
  background-image: url("/images/map-bg.jpg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  @media (min-width: 1700px) {
    background-size: 1366px auto;
  }
`

const ContactUs = () => {
  const { t } = useTranslation()

  const infoItems = [
    {
      icon: IconCallImage,
      title: t("phone"),
      value: APREMIA_PHONE_FORMATTED,
    },
    {
      icon: IconMailImage,
      title: t("email"),
      value: APREMIA_EMAIL,
    },
    // {
    //   icon: IconLocationImage,
    //   title: t("address"),
    //   value: "5455 SW 8TH ST, STE 265, CORAL GABLES, FL 33134",
    // },
  ]

  return (
    <Layout>
      <SEO title={t("contact_us")} />

      {/* <Map
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDmaa61T0B4T-vHjcNbBsiaCGqUgbi1Edw&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `340px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      /> */}
      {/* <Map /> */}

      <Container className="py-4 py-md-5 pb-5">
        <Row>
          <Col xs="12" md="4" lg="3" className="border-end">
            <h3 className="d-none d-md-block">{t("contact_us")}</h3>

            {infoItems.map((item, index) => (
              <div key={index} className="mb-4">
                <div className="d-flex align-items-center">
                  <Icon as={item.icon} className="me-2" />
                  <span className="fw-light">{item.title}</span>
                </div>
                <b>{item.value}</b>
              </div>
            ))}

            <hr className="my-4 d-md-none" />
          </Col>

          <Col xs="12" md className="px-md-5 px-lg-7">
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

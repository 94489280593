import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"

import { submitMessageRequest } from "../services"

import SuccessImage from "../images/success.png"

const initialValues = {
  name: "",
  phoneNumber: "",
  email: "",
  callTime: "Mañana 8:00 am - 12:00 pm",
  message: "",
}

// RegEx for phone number validation
const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const SentDialog = styled.div`
  background-color: ${props => props.theme.colors["body-bg"]};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  & > img {
    height: 120px;
    margin-bottom: 1rem;
  }
`

const ContactForm = () => {
  const { t } = useTranslation()

  const [visibleSentDialog, setVisibleSentDialog] = useState(false)
  const [ sending, setSending ] = useState(false)
  const [ formError, setFormError ] = useState(null)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("required_field_error")),
    email: Yup.string()
      .email(t("format_invalid_field_error"))
      .required(t("required_field_error")),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, t("format_invalid_field_error"))
      .required(t("required_field_error")),
    message: Yup.string(),
  })

  return (
    <div className="position-relative">
      <h3>{t("send_us_msg")}</h3>

      <p className="fw-bold text-danger">
        {formError}
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            setSending(true)
            await submitMessageRequest(values);
            setVisibleSentDialog(true);
          } catch (err) {
            setFormError('Tuvimos un problema guardando su información, por favor intente nuevamente')
          }
          finally {
            setSending(false)
          }
        }}
      >
        {props => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleSubmit,
          } = props
          return (
            <Form onSubmit={handleSubmit}>
              <fieldset disabled={isSubmitting}>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label className="required">{t("form_name")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPhoneNumber">
                  <Form.Label className="required">
                    {t("form_phone_number")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.phoneNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label className="required">{t("form_email")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCallTime">
                  <Form.Label>¿A qué hora deseas que te llamemos?</Form.Label>
                  <Form.Select
                    name="callTime"
                    value={values.callTime}
                    onChange={handleChange}
                  >
                    <option value="Mañana 8:00 am - 12:00 pm">Mañana 8:00 am - 12:00 pm</option>
                    <option value="Tarde 12:00 pm - 6:00 pm">Tarde 12:00 pm - 6:00 pm</option>
                    <option value="Noche 6:00 pm - 8:00 pm">Noche 6:00 pm - 8:00 pm</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMessage">
                  <Form.Label>{t("form_message")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    isInvalid={!!errors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </fieldset>
              
              <Button type="submit" disabled={isSubmitting}>
                {sending ? 'Enviando...' : t("send_msg")}
              </Button>
            </Form>
          )
        }}
      </Formik>

      {visibleSentDialog && (
        <SentDialog 
          onClick={() => setVisibleSentDialog(false)}
          className="d-flex flex-column align-items-center justify-content-center text-center"
        >
          <img src={SuccessImage} alt="success" />
          <h1>{t("form_sent_title")}</h1>
          <h3>{t("form_sent_subtitle")}</h3>
        </SentDialog>
      )}
    </div>
  )
}

export default ContactForm
